/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import {
  privateEncrypt
} from "crypto";
import ApiClient from "../ApiClient";
import AlgorithmResultRequest from '../model/AlgorithmResultRequest';
import ConfigGroupEnum from '../model/ConfigGroupEnum';
import SearchTypeEnum from '../model/SearchTypeEnum';
import SubmitTaskResponseResponse from '../model/SubmitTaskResponseResponse';

/**
 * Algorithm service.
 * @module api/AlgorithmApi
 * @version v1
 */
export default class AlgorithmApi {

  /**
   * Constructs a new AlgorithmApi. 
   * @alias module:api/AlgorithmApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the algorithmDownloadGet operation.
   * @callback module:api/AlgorithmApi~algorithmDownloadGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 下载任务结果
   * @param {module:api/AlgorithmApi~algorithmDownloadGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  algorithmDownloadGet(taskId, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'TaskId': taskId
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/Download', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the algorithmGetResultsGet operation.
   * @callback module:api/AlgorithmApi~algorithmGetResultsGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 获取任务结果
   * @param {module:api/AlgorithmApi~algorithmGetResultsGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  algorithmGetResultsGet(taskId, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'TaskId': taskId
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/GetResults', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the algorithmSendEmailGet operation.
   * @callback module:api/AlgorithmApi~algorithmSendEmailGetCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 发送结果到邮箱
   * @param {module:api/AlgorithmApi~algorithmSendEmailGetCallback} callback The callback function, accepting three arguments: error, data, response
   */
  algorithmSendEmailGet(taskId, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'TaskId': taskId
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/SendEmail', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the algorithmSendEmailWhenFinlishedPost operation.
   * @callback module:api/AlgorithmApi~algorithmSendEmailWhenFinlishedPostCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 提交一个事件，在比对结果完成后，将发送结果到邮箱
   * 1. 重复提交不会发送多封邮件。  2. 任务结束后提交不会发送邮件。
   * @param {Object} opts Optional parameters
   * @param {module:api/AlgorithmApi~algorithmSendEmailWhenFinlishedPostCallback} callback The callback function, accepting three arguments: error, data, response
   */
  algorithmSendEmailWhenFinlishedPost(opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/SendEmailWhenFinlished', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the algorithmSubmitDSBTaskPost operation.
   * @callback module:api/AlgorithmApi~algorithmSubmitDSBTaskPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SubmitTaskResponseResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 提交查询
   * @param {Object} opts Optional parameters
   * @param {module:api/AlgorithmApi~algorithmSubmitDSBTaskPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SubmitTaskResponseResponse}
   */
  /**
   * Callback function to receive the result of the algorithmSubmitOffTargetTaskPost operation.
   * @callback module:api/AlgorithmApi~algorithmSubmitOffTargetTaskPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SubmitTaskResponseResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 提交查询
   * @param {Object} opts Optional parameters
   * @param {module:api/AlgorithmApi~algorithmSubmitOffTargetTaskPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SubmitTaskResponseResponse}
   */

  /**
   * Callback function to receive the result of the algorithmSubmitOnTargetTaskPost operation.
   * @callback module:api/AlgorithmApi~algorithmSubmitOnTargetTaskPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SubmitTaskResponseResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 提交查询
   * @param {Object} opts Optional parameters
   * @param {module:api/AlgorithmApi~algorithmSubmitOnTargetTaskPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SubmitTaskResponseResponse}
   */
  algorithmSubmitGrnaTaskPost(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'enzyme': opts['enzyme'],
      'genome': opts['Genome'],
      'content': opts['content'],
      'data_type': opts['data_type'],
      'is_on': opts['ON'],
      'is_off': opts['OFF'],
      'is_dsb': opts['DSB'],
      'gc_content_min': opts['gc_content_min'],
      'gc_content_max': opts['gc_content_max'],
      'dsb_score_min': opts['dsb_score_min'],
      'dsb_score_max': opts['dsb_score_max'],
      'off_score_min': opts['off_score_min'],
      'off_score_max': opts['off_score_max'],
      'on_score_min': opts['on_score_min'],
      'on_score_max': opts['on_score_max'],
      'mismatch_max': opts['mismatch_max'],
      'AutoSendEmail': opts['AutoSendEmail'],
    };

    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    // let contentTypes = ['multipart/form-data','charset=utf-8'];
    // console.log(formParams);
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = SubmitTaskResponseResponse;

    return this.apiClient.callApi(
      '/Algorithm/SubmitGrnaTask', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  CheckGeneNameGet(Genome, GeneName, callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      // 'TaskId': taskId
      'Genome': Genome,
      'GeneName': GeneName,
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/CheckGeneName', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  algorithmSubmitEnhancerTaskPost(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'ModelName': opts['ModelName'],
      'MotifCutoff': opts['MotifCutoff'],
      'Content': opts['content'],
      'AutoSendEmail': opts['AutoSendEmail'],
    };

    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    // let contentTypes = ['multipart/form-data','charset=utf-8'];
    // console.log(formParams);
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = SubmitTaskResponseResponse;

    return this.apiClient.callApi(
      '/Algorithm/SubmitEnhancerTask', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  algorithmSubmitLocationTaskPost(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'ModelName': opts['ModelName'],
      'MotifCutoff': opts['MotifCutoff'],
      'Content': opts['content'],
      'AutoSendEmail': opts['AutoSendEmail'],
    };

    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    // let contentTypes = ['multipart/form-data','charset=utf-8'];
    // console.log(formParams);
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = SubmitTaskResponseResponse;

    return this.apiClient.callApi(
      '/Algorithm/SubmitLocationTask', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  // newCasSummary

  algorithmQuerySpeciesByOptionGet(lengthMin, lengthMax, strainMin, strainMax, selectBlastp, selectPositive, selectDomain, withBlastp,
    withPositive,
    withDomain, callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      // 'TaskId': taskId
      'lengthMin': lengthMin,
      'lengthMax': lengthMax,
      'strainMin': strainMin,
      'strainMax': strainMax,
      'selectBlastp': selectBlastp,
      'selectPositive': selectPositive,
      'selectDomain': selectDomain,
      'withBlastp': withBlastp,
      'withPositive': withPositive,
      'withDomain': withDomain
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/QuerySpeciesByOption', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  algorithmQueryStrainOptionGet(
    speciesTaxon,
    lengthMin,
    lengthMax,
    distanceMin,
    distanceMax,
    drNumMin,
    drNumMax,
    drLengthMin,
    drLengthMax,
    spacerLengthMin,
    spacerLengthMax,
    withinGeneMin,
    withinGeneMax,
    selectBlastp,
    selectPositive,
    selectDomain,
    selectCas124,
    withBlastp,
    withPositive,
    withDomain,
    withCas124,
    callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'speciesTaxon': speciesTaxon,
      'lengthMin': lengthMin,
      'lengthMax': lengthMax,
      'distanceMin': distanceMin,
      'distanceMax': distanceMax,
      'drNumMin': drNumMin,
      'drNumMax': drNumMax,
      'drLengthMin': drLengthMin,
      'drLengthMax': drLengthMax,
      'spacerLengthMin': spacerLengthMin,
      'spacerLengthMax': spacerLengthMax,
      'withinGeneMin': withinGeneMin,
      'withinGeneMax': withinGeneMax,
      'selectBlastp': selectBlastp,
      'selectPositive': selectPositive,
      'selectDomain': selectDomain,
      'selectCas124': selectCas124,
      'withBlastp': withBlastp,
      'withPositive': withPositive,
      'withDomain': withDomain,
      'withCas124': withCas124
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/Algorithm/QueryStrainOption', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }







































}