<template>
  <div class="prediction">
    <div class="target-top">
      <h1 style="color: #1fa1a0">AIdit_gRNA</h1>
      <hr />
      <div v-if="InternalUser == 1">
        <p>
          Understanding the CRISPR performance in various cellular environments
          is crucial to enhancing its application in biological studies and
          therapies. Empowered by the high throughput gRNA-target paired
          library, we comprehensively depicted the on-target editing efficiency,
          off-target editing specificity, and DSB repairing profiles of
          <span style="color: #f44336">929,180</span> gRNAs across two human
          cell lines (K562 and Jurkat), covering all protein-coding genes and
          <span style="color: #f44336">17,177</span>
          non-coding genes. Based on this high-quality and largest-ever
          gRNA-target pair dataset and machine learning algorithms, we developed
          corresponding computational models that outperformed current
          stategies, namely
          <span style="color: #2196f3">AIdit_ON</span> for on-target prediction,
          <span style="color: #2196f3">AIdit_OFF</span> for off-target
          prediction and
          <span style="color: #2196f3">AIdit_DSB</span>
          for prediction of SpCas9-induced DSB repair outcomes. Together, this
          study will provide basic research and gene therapy support for more
          effective and accurate applications of CRISPR/Cas9.
        </p>
      </div>
      <p v-if="InternalUser == 0"></p>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1" @click="active(1)">Sequence</el-menu-item>
      <el-menu-item index="2" @click="active(3)">Gene</el-menu-item>
    </el-menu>
    <div class="contentArea" v-show="enterSequence">
      <ul>
        <li>
          <div class="title" @click="active(1)">
            <img :src="num == 1 ? image.minusSign : image.plus" alt="" />
            <p>Enter FASTA text</p>
          </div>
          <div v-if="num === 1" class="FASTA-text">
            <p>
              Please enter SpCas9 target sequences in FASTA format. It will
              automatically search all 63bp target sequences as input to predict
              on-target activities of the corresponding gRNA.
              <span @click="copy()" class="textExample">Example</span>
              <span @click="clear()" class="textExample">Clear</span>
            </p>
            <p>FASTA sequence</p>
            <el-input
              type="textarea"
              :rows="2"
              v-model="fastaSequence"
              resize="none"
              class="place"
              :class="{ hideAfter: hideAfter }"
              @focus="hideAfter = true"
            ></el-input>
          </div>
        </li>
        <li>
          <div class="title" @click="active(2)">
            <img :src="num == 2 ? image.minusSign : image.plus" alt="" />
            <p>Upload a FASTA file</p>
          </div>
          <div v-if="num === 2" class="FASTA-file">
            <div class="file" @click="openFile">
              <img src="@/assets/file.png" class="icon" />
              <div v-show="1 != 1">
                <input
                  type="file"
                  id="upFile"
                  ref="upFile"
                  @change="getFile($event)"
                  accept="fasta"
                />
              </div>
              <div class="crispr_test">
                <div class="fileValue" v-if="fileValue">{{ fileValue }}</div>
                <div class="fileValue" v-else>Please select a FASTA file.</div>
              </div>
            </div>
            <br />
            <!-- <a href='https://crispr-aidit.com/examples.txt' target="_blank">{{ exampleValue }}</a> -->
            <div class="example" @click="example">
              {{ exampleValue }}
            </div>
            <div class="download">
              <!-- <span>Download example file >>> </span> -->
              <a href="https://crispr-aidit.com/Aldit_gRNA-example.fasta"
                >Download</a
              >
            </div>
            <div class="example-input" v-show="exampleShow">
              <div>
                >NM_001199868
                <br />
                ACATCCGGCCGCCGGCACTGGATTGCTTCTGTCTGGCGGCGGCAGCATGGCGGCGGGGGCGGCTGAGGCAGCTGTAGCGGCCGTGGAGGAGGTCGGCTCAGCCGGGCAGTTTGAGGAGCTGCTGCGCCTCAAAGCCAAGTCCCTCCTTGTGGTCCATTTCTGGGCACCATGGGCTCCACAGTGTGCACAGATGAACGAAGTTATGGCAGAGTTAGCTAAAGAACTCCCTCAAGTTTCATTTGTGAAGTTGGAAGCTGAAGGTGTTCCTGAAGTATCTGAAAAATATGAAATTAGCTCTGTTCCCACTTTTCTGTTTTTCAAGAATTCTCA
                <br />
                >NR_033843
                <br />
                AGACCCCCAAAAGAGGCTGCAGATTCTTAGTGGACAGCTCTCATATCCCCTCCAGGGAAAATGATGAAAAATGTCTTCAGAAAAATAGCTTTATGAAGGCAAGAGGACTTTTTGCACCTGCAAGACATGTCTGAAAGCTCCTCAGGTCAAAACACAAGTAGTCAAGAGATTTGTCCATCTAATTCATCATACTACTCTGATCCAAGTGAGACCCACACTGGGTCAGCTCCATCAAGGAGAAGAACATTCCACCCACGTTTGGATTCTGGAATATCATCCTTGCTTCCATCAGATTCTTGTAAGTACCTCACTTGGCACAAGGTAGACATGACG
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="contentArea" v-show="enterGene">
      <ul>
        <li>
          <div class="title">
            <p>Enter a gene name</p>
          </div>
          <div class="gene-name">
            <p>
              Select SpCas9 target sequences by gene symbol. Please enter a gene
              symbol and its corresponding species.
              <span @click="copyGene()" class="textExample">Example</span>
              <span @click="clearGene()" class="textExample">Clear</span>
            </p>
            <div>
              <div class="geneName">
                <h3>Gene name</h3>
                <!--    @focus="focusFn"
                  @blur="blurFn" -->
                <el-input
                  type="text"
                  @input="inputFn(500)"
                  placeholder="Enter a gene name (eg. Ddx5)"
                  v-model="GeneName"
                  resize="none"
                  class="geneName-input"
                ></el-input>
                <p :class="QueryStatus">{{ tips }}</p>
                <el-table :data="GeneNameData" border style="width: 650px">
                  <el-table-column label="Gene Name" min-width="100px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nameLabel.split("-")[0] }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="RefSeq mRNA ID" min-width="135px"
                    ><template slot-scope="scope">
                      <span>{{ scope.row.nameLabel.split("-")[1] }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="availableIDs" min-width="100px">
                    <template slot-scope="scope">
                      <div v-html="scope.row.availableIDs"></div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="editing-enzyme">
      <p>Select genome</p>
      <div class="select-type">
        <div class="Genome">
          <h3>Genome</h3>
          <el-select
            v-model="Genome"
            class="select-250 Genome-select"
            placeholder="Please select gene type"
          >
            <el-option
              v-for="item in geneList"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="models">
      <p>
        Select enzyme
        <el-tooltip
          content="HiFi-Cas9, LZ3-Cas9 and SpCas9-NG are not supported for use in the AIdit_DSB model."
          placement="right"
        >
          <button><img src="../assets/sign.png" alt="" /></button>
        </el-tooltip>
      </p>
      <div class="checkbox">
        <el-checkbox-group v-model="selectEnzymeList" @change="ChangeEnzyme">
          <el-checkbox
            v-for="item in enzymeList"
            :key="item.value"
            :label="item.value"
            :disabled="enzymedisabled.includes(item.value)"
            >{{ item.value }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="models">
      <p>
        Select model
        <el-tooltip
          content="The AIdit_DSB model does not support the use of HiFi-Cas9, LZ3-Cas9 and SpCas9-NG."
          placement="right"
        >
          <button><img src="../assets/sign.png" alt="" /></button>
        </el-tooltip>
      </p>
      <div class="checkbox">
        <el-checkbox-group v-model="checkList" @change="handleCheckedChange">
          <el-checkbox label="AIdit_ON" :disabled="on"></el-checkbox>
          <el-checkbox label="AIdit_OFF" :disabled="off"></el-checkbox>
          <el-checkbox label="AIdit_DSB" :disabled="dsb"></el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="params">
      <p>Set params</p>
      <div class="filter">
        <span>GC_Content (%):</span
        ><el-input
          v-model="gc_content_min"
          placeholder="0"
          @input="numValid('gc_content_min')"
          style="margin-right: 15px"
        ></el-input>
        <h6>~</h6>
        <el-input
          v-model="gc_content_max"
          placeholder="100"
          @input="numValid('gc_content_max')"
        ></el-input>
        <!-- <span>Efficiecy for AIdit_ON (%):</span
        ><el-input
          v-model="on_score_min"
          placeholder="0"
          @input="numValid('on_score_min')"
          style="margin-right: 15px"
        ></el-input> -->
        <!-- <h6>~</h6>
        <el-input
          v-model="on_score_max"
          placeholder="100"
          @input="numValid('on_score_max')"
        ></el-input> -->
      </div>
      <div class="filter">
        <span>Max mismatch number for off-target:</span>
        <el-input
          v-model="mismatch_max"
          placeholder="2"
          @input="mismatchNumValid('mismatch_max')"
        ></el-input>
        <!-- <span>Score for AIdit_OFF (%):</span
        ><el-input
          v-model="off_score_min"
          placeholder="0"
          @input="numValid('off_score_min')"
          style="margin-right: 15px"
        ></el-input>
        <h6>~</h6>
        <el-input
          v-model="off_score_max"
          placeholder="100"
          @input="numValid('off_score_max')"
        ></el-input> -->
      </div>
      <div class="filter">
        <span>Probability for AIdit_DSB (%):</span
        ><el-input
          v-model="dsb_score_min"
          placeholder="0"
          @input="numValid('dsb_score_min')"
          style="margin-right: 15px"
        ></el-input>
        <h6>~</h6>
        <el-input
          v-model="dsb_score_max"
          placeholder="100"
          @input="numValid('dsb_score_max')"
        ></el-input>
      </div>
    </div>
    <!-- <div class="autoSendEmail">
      <p>Send to email</p>
      <el-checkbox v-model="AutoSendEmail">send to email</el-checkbox>
    </div> -->
    <el-button
      class="submit"
      type="primary"
      @click.prevent="sendDetail"
      :disabled="disabled"
    >
      Submit
    </el-button>
  </div>
</template>
<script>
import Cookies from "js-cookie";

import {
  ApiClient,
  IdempotenceApi,
  AlgorithmApi,
  ManagerCentreApi,
} from "@/api";
export default {
  data() {
    return {
      InternalUser: 3,
      hideAfter: false,
      num: 1,
      textarea: "",
      checked: false,
      exampleShow: false,
      disabled: false,
      placeholderFastaSequence:
        ">NM_001199868\nACATCCGGCCGCCGGCACTGGATTGCTTCTGTCTGGCGGCGGCAGCATGGCGGCGGGGGCGGCTGAGGCAGCTGTAGCGGCCGTGGAGGAGGTCGGCTCAGCCGGGCAGTTTGAGGAGCTGCTGCGCCTCAAAGCCAAGTCCCTCCTTGTGGTCCATTTCTGGGCACCATGGGCTCCACAGTGTGCACAGATGAACGAAGTTATGGCAGAGTTAGCTAAAGAACTCCCTCAAGTTTCATTTGTGAAGTTGGAAGCTGAAGGTGTTCCTGAAGTATCTGAAAAATATGAAATTAGCTCTGTTCCCACTTTTCTGTTTTTCAAGAATTCTCA",
      geneList: [
        {
          key: "Homo sapiens (GRCh38/hg38)",
          value: "hg38",
        },
        {
          key: "Mus musculus (GRCm39/mm39)",
          value: "mm39",
        },
      ],
      enzymeList: [],
      Genome: "hg38",
      image: {
        minusSign: require("@/assets/minusSign.png"),
        plus: require("@/assets/plus.png"),
      },
      exampleValue: "Example file: Aldit_gRNA-example.fasta",
      value: "",
      api: new IdempotenceApi(ApiClient.instance),
      algorithmApi: new AlgorithmApi(ApiClient.instance),
      managerCentreApi: new ManagerCentreApi(ApiClient.instance),
      gene: "",
      fastaSequence: "",
      enzyme: "",
      fastaFile: "",
      fileList: [],
      filePath: "",
      maxLength: 0,
      fileArr: [],
      fileValue: "",
      enzymeToModel: {},
      modelToEnzyme: {},
      enzymedisabled: [],
      enzymeValueList: [],
      submitting: false,
      checkList: ["AIdit_ON"],
      ON: 1,
      OFF: 0,
      DSB: 0,
      on: true,
      off: false,
      dsb: false,
      activeIndex: "1",
      enterSequence: true,
      enterGene: false,
      gc_content_min: "0",
      gc_content_max: "100",
      dsb_score_min: "10",
      dsb_score_max: "100",
      off_score_min: "10",
      off_score_max: "100",
      on_score_min: "0",
      on_score_max: "100",
      mismatch_max: 2,
      data_type: "sequence",
      content: "",
      GeneName: "",
      GenomeInt: "",
      GeneNameData: [],
      newArr: [],
      list: [],
      loading: false,
      restaurants: [],
      timeout: null,
      tips: "no result",
      QueryStatus: "",
      AutoSendEmail: false,
      selectEnzymeList: ["SpCas9"],
      fileResult: "",
    };
  },
  mounted() {
    this.maxLength = 1024 * 1024 * 2.5;
    this.query();
    if (Cookies.get("user")) {
      if (JSON.parse(Cookies.get("user_info")).isInternalUser == 1) {
        this.InternalUser = 1;
      } else {
        this.InternalUser = 0;
      }
    } else {
      this.InternalUser = 0;
    }
  },
  methods: {
    // blurFn() {
    //   this.iptShow = false;
    // },
    inputFn(delay) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.Genome == "hg38") {
            this.GenomeInt = 1;
          } else if (this.Genome == "mm39") {
            this.GenomeInt = 3;
          }
          if (this.GeneName != "") {
            this.algorithmApi.CheckGeneNameGet(
              this.GenomeInt,
              this.GeneName,
              this.GeneNameResult
            );
          }
          this.timeout = null;
        }, delay);
      } else {
        this.timeout = setTimeout(() => {
          if (this.Genome == "hg38") {
            this.GenomeInt = 1;
          } else if (this.Genome == "mm39") {
            this.GenomeInt = 3;
          }
          if (this.GeneName != "") {
            this.algorithmApi.CheckGeneNameGet(
              this.GenomeInt,
              this.GeneName,
              this.GeneNameResult
            );
          }
          this.timeout = null;
        }, delay);
      }
    },
    // 关键词变色
    upperKeywords(sentence, keyword) {
      let splitPiece = sentence.toUpperCase().split(keyword.toUpperCase());
      let start = 0;
      let sentenceNew = sentence.substr(0, splitPiece[0].length);
      start += splitPiece[0].length;
      for (let i = 1; i < splitPiece.length; i++) {
        sentenceNew +=
          "<strong style='color:#2196f3'>" +
          sentence.substr(start, keyword.length) +
          "</strong>";
        start += keyword.length;
        sentenceNew += sentence.substr(start, splitPiece[i].length);
        start += splitPiece[i].length;
      }
      return sentenceNew;
    },

    // 匹配到的基因名结果
    GeneNameResult(e, Data, resp) {
      Data = resp.body.result.records;
      this.GeneNameData = Data;
      for (let i in this.GeneNameData) {
        let sentence = this.GeneNameData[i].availableIDs.replaceAll("|", ", ");
        let keyword = this.GeneName;
        this.GeneNameData[i].availableIDs = this.upperKeywords(
          sentence,
          keyword
        );
      }
      if (this.GeneNameData.length > 0) {
        if (this.GeneNameData[0].exactMatch == 1) {
          if (this.GeneNameData.length > 100) {
            this.$message({
              message: "Please enter a more accurate gene name!",
              type: "warning",
            });
            this.tips =
              "More than 100 genes have been found in our database,please provide more detailed keywords.";
            this.QueryStatus = "warning";
            this.GeneNameData = this.GeneNameData.slice(0, 100);
          } else {
            this.tips =
              "This gene is included in our database, you can submit and make the prediction.";
            this.QueryStatus = "success";
          }
        } else {
          this.$message({
            message: "No corresponding gene name!",
            type: "error",
          });
          this.tips =
            "This gene was not found in our database, perhaps you means:";
          this.QueryStatus = "error";
          this.GeneNameData = this.GeneNameData.slice(0, 100);
        }
      } else {
        this.$message({
          message: "No corresponding gene name!",
          type: "error",
        });
        this.tips =
          "This gene was not found in our database, please try other keywords.";
        this.QueryStatus = "error";
      }
    },
    ChangeEnzyme(value) {
      for (var i in this.enzymeToModel) {
        if (value.indexOf(i) > -1) {
          // if (this.enzymeToModel[i].indexOf("ON") > -1) {
          //   this.on = false;
          // } else {
          //   this.on = true;
          // }
          if (this.enzymeToModel[i].indexOf("OFF") > -1) {
            this.off = false;
          } else {
            this.off = true;
          }
          if (this.enzymeToModel[i].indexOf("DSB") > -1) {
            this.dsb = false;
          } else {
            this.dsb = true;
          }
        }
      }
    },
    handleCheckedChange(value) {
      this.enzymeValueList = [];
      this.enzymedisabled = [];
      // console.log(this.modelToEnzyme);
      // this.ON = Object.values(value).indexOf("AIdit_ON") > -1 ? 1 : 0;
      this.OFF = Object.values(value).indexOf("AIdit_OFF") > -1 ? 1 : 0;
      this.DSB = Object.values(value).indexOf("AIdit_DSB") > -1 ? 1 : 0;
      for (var j in this.enzymeList) {
        this.enzymeValueList.push(this.enzymeList[j].value);
      }
      // console.log("this.enzymeValueList", this.enzymeValueList);
      if (this.DSB == 1) {
        // console.log(this.modelToEnzyme["DSB"]);
        this.enzymedisabled = this.enzymeValueList.filter(
          (itemA) => !this.modelToEnzyme["DSB"].some((itemB) => itemA === itemB)
        );
        // console.log(this.enzymedisabled);
      }
    },
    handleSelect(key, keyPath) {
      if (key == "1") {
        this.enterSequence = true;
        this.enterGene = false;
      } else {
        this.enterGene = true;
        this.enterSequence = false;
      }
    },
    //利用函数传参达到复用
    numValid(val) {
      // console.log("numValid100", this[val]);
      if (this[val] > 100) {
        this.$message({
          message: "Please enter a number between 0 and 100!",
          type: "error",
        });
        this[val] = "";
      }
      //只能输入数字
      oninput = this[val] = this[val].replace(/[^\d]/g, "");
    },
    mismatchNumValid(val) {
      // console.log("mismatchNumValid4", this[val]);
      if (this[val] > 4) {
        this.$message({
          message: "Please enter a number between 0 and 4!",
          type: "error",
        });
        this[val] = "";
      }

      //只能输入数字
      oninput = this[val] = this[val].replace(/[^\d]/g, "");
    },
    openFile() {
      document.getElementById("upFile").click();
    },
    // 改变文件状态（上传）时自动触发
    getFile(event) {
      var file = event.target.files;
      this.fileArr = [];
      for (var i = 0; i < file.length; i++) {
        this.filePath = URL.createObjectURL(file[i]);
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx !== -1) {
          var ext = imgName.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (ext === "fasta") {
            const isLtXM = file[i].size / 1024 / 1024 / 2 <= 1;
            if (isLtXM) {
              this.fileArr.push(file[i]);
              this.fileValue = this.fileArr[0].name;
              // 新建一个FileReader
              const reader = new FileReader();
              // 读取文件
              reader.readAsText(file[0], "UTF-8");
              var _this = this;
              // 读取完文件之后会回来这里
              reader.onload = function (e) {
                // 读取文件内容
                const fileString = e.target.result;
                // _this.fastaSequence = fileString;
                _this.fileResult = fileString;
                // 接下来可对文件内容进行处理
                // console.log(fileString);
                var fileSeq = fileString.replaceAll("\r", "").split("\n");
                // console.log(fileSeq);
                if (fileSeq.indexOf(">") == -1) {
                  for (var j = 0; j < fileSeq.length; j++) {
                    if (fileSeq[j].split(">").length > 1) {
                      // console.log(fileSeq[j].split(">"));
                      // console.log(this);
                      // _this.names.push(fileSeq[j].split(">")[1]);
                      // console.log("name");
                    } else {
                      if (fileSeq[j].length < 63) {
                        _this.$message({
                          message:
                            "Please enter a FASTA sequence of at least 63bp!",
                          type: "error",
                        });
                        _this.fileValue = "";
                        _this.submitting = false;
                      } else {
                        if (!/^[ATCGatcg]{1,}$/.test(fileSeq[j])) {
                          _this.$message({
                            message:
                              "A set of sequences can only contain A/T/C/G!",
                            type: "error",
                          });
                          _this.fileValue = "";
                          _this.submitting = false;
                          return;
                        }
                      }
                    }
                  }
                } else {
                  if (fileSeq.length < 63) {
                    _this.$message({
                      message:
                        "Please enter a FASTA sequence of at least 63bp!",
                      type: "error",
                    });
                    _this.fileValue = "";
                    _this.submitting = false;
                  } else {
                    if (!/^[ATCGatcg]{1,}$/.test(fileSeq)) {
                      _this.$message({
                        message: "A set of sequences can only contain A/T/C/G!",
                        type: "error",
                      });
                      _this.fileValue = "";
                      _this.submitting = false;
                    }
                  }
                }
              };
            } else {
              this.$message({
                message: "The uploaded file must be no larger than 2MB！",
                type: "error",
              });
              return;
            }
          } else {
            this.fileValue = "";
            this.$message({
              message: "The uploaded file must be in FASTA format!",
              type: "error",
            });
            return;
          }
        }
      }
      event.srcElement.value = ""; //及时清空
    },
    // 刷新TaskId
    refreshTaskId() {
      var p = new Promise((resolve, reject) => {
        this.api.newguidGet((e, data, res) => {
          if (e) {
            reject(e);
            this.submitting = false;
          } else {
            Cookies.set("guid", res.text);
            resolve();
          }
        });
      });
      return p;
    },
    query() {
      // 获取酶等参数
      this.managerCentreApi.managerParamsConfigCollectionGet((e, data, res) => {
        if (data?.success) {
          res.body.result.records.forEach((i) => {
            if (i.group === 1 && i.type === 2) {
              this.enzymeList.push(i);
            }
            if (this.enzymeList.length) {
              var defaultEnzyme = this.enzymeList.filter((a) => a.isDefault);
              this.enzyme = defaultEnzyme.length
                ? defaultEnzyme[0].value
                : this.enzymeList[0].value;
            }
            if (i.type === 2) {
              let model, enzyme;
              if (i.group === 1) {
                model = "ON";
              } else if (i.group === 2) {
                model = "OFF";
              } else if (i.group === 3) {
                model = "DSB";
              }
              enzyme = i.value;
              if (enzyme in this.enzymeToModel) {
                this.enzymeToModel[enzyme].push(model);
              } else {
                this.enzymeToModel[enzyme] = [model];
              }
              if (model in this.modelToEnzyme) {
                this.modelToEnzyme[model].push(enzyme);
              } else {
                this.modelToEnzyme[model] = [enzyme];
              }
            }
          });
          // console.log(this.modelToEnzyme);
        }
      });
    },
    // 发起请求
    sendRequest() {
      // console.log(this.ON, this.OFF, this.DSB);
      // console.log(this.selectEnzymeList.join());
      this.AutoSendEmail = this.AutoSendEmail == true ? 1 : 0;
      if (this.data_type == "file" || this.data_type == "sequence") {
        // this.data_type = "Text";
        this.data_type = 1;
        this.content = this.fastaSequence;
        if (this.fileValue) {
          this.content = this.fileResult;
        }
      } else {
        this.content = this.GeneName;
        this.data_type = 2;
      }
      // console.log(typeof this.data_type);
      this.$store.commit("updateGene", this.data_type);
      // console.log(this.ON, this.OFF, this.DSB);
      this.disabled = true;
      this.algorithmApi.algorithmSubmitGrnaTaskPost(
        {
          enzyme: this.selectEnzymeList.join(),
          content: this.content,
          Genome: this.Genome,
          data_type: this.data_type,
          ON: this.ON,
          OFF: this.OFF,
          DSB: this.DSB,
          gc_content_min: Number(this.gc_content_min),
          gc_content_max: Number(this.gc_content_max),
          dsb_score_min: Number(this.dsb_score_min),
          dsb_score_max: Number(this.dsb_score_max),
          off_score_min: Number(this.off_score_min),
          off_score_max: Number(this.off_score_max),
          on_score_min: Number(this.on_score_min),
          on_score_max: Number(this.on_score_max),
          mismatch_max: this.mismatch_max,
          AutoSendEmail: this.AutoSendEmail,
        },
        (e, data) => {
          // console.log("gRNARequestRequestRequestRequestRequest",data)
          if (data?.success) {
            this.$message({
              message: "Task submitted successfully",
              type: "success",
            });
            this.$router.push({
              path: "/gRNAResult",
              query: {
                taskGuid: data.result.taskGuid,
                enzyme: this.enzyme,
              },
            });
            this.disabled = false;
          } else {
            this.disabled = false;
          }
          this.submitting = false;
        }
      );
    },
    // 输入验证
    sendDetail() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      // console.log(this.fileValue);
      if (this.num == "1") {
        this.data_type = "sequence";
      } else if (this.num == "2") {
        this.data_type = "file";
      } else {
        this.data_type = "gene";
      }
      // this.sendRequest();
      if (this.enterSequence == true) {
        if (this.data_type === "sequence") {
          if (this.fastaSequence) {
            // 是否存在id
            if (this.fastaSequence.indexOf(">") != -1) {
              var fastaSeq = this.fastaSequence
                .replaceAll("\r", "")
                .split("\n");
              // console.log(fastaSeq);
              for (var j = 0; j < fastaSeq.length; j++) {
                // id
                if (fastaSeq[j].split(">").length > 1) {
                  console.log("fastaSeq[j].split(" > ")");
                } else {
                  if (fastaSeq[j].length < 63) {
                    this.$message({
                      message:
                        "Please enter a FASTA sequence of at least 63bp!",
                      type: "error",
                    });
                    this.submitting = false;
                  } else {
                    if (!/^[ATCGatcg]{1,}$/.test(fastaSeq[j])) {
                      this.$message({
                        message: "A set of sequences can only contain A/T/C/G!",
                        type: "error",
                      });
                      this.submitting = false;
                    }
                  }
                }
              }
            } else {
              if (this.fastaSequence.length < 63) {
                this.$message({
                  message: "Please enter a FASTA sequence of at least 63bp!",
                  type: "error",
                });
                this.submitting = false;
              } else {
                if (!/^[ATCGatcg]{1,}$/.test(this.fastaSequence)) {
                  this.$message({
                    message: "A set of sequences can only contain A/T/C/G!",
                    type: "error",
                  });
                  this.submitting = false;
                }
              }
            }
          } else {
            this.$message({
              message: "FASTA text cannot be empty!",
              type: "error",
            });
            this.submitting = false;
          }
          if (this.submitting) {
            this.sendRequest();
          }
        } else if (this.data_type === "file") {
          if (this.fileArr.length !== 0) {
            // var selectedFile = document.getElementById("upFile").files[0];
            // var reader = new FileReader(); //这是核心,读取操作就是由它完成.
            // reader.readAsText(selectedFile); //读取文件的内容,也可以读取文件的URL
            // reader.onload = function () {
            //   //当读取完成后回调这个函数,然后此时文件的内容存储到了result中,直接操作即可
            //   sessionStorage.setItem("onFileResult", this.fastaSequence);
            // };
            if (this.submitting) {
              this.sendRequest();
            }
          } else {
            this.$message({
              message: "FASTA file cannot be empty!",
              type: "error",
            });
            this.submitting = false;
          }
        }
      } else {
        if (this.GeneName) {
          if (this.QueryStatus == "success") {
            this.sendRequest();
          } else if (this.QueryStatus == "warning") {
            this.$message({
              message: "Please enter a more accurate gene name!",
              type: "warning",
            });
            this.submitting = false;
          } else {
            this.$message({
              message: "No corresponding gene name!",
              type: "error",
            });
            this.submitting = false;
          }
        } else {
          this.$message({
            message: "GeneName cannot be empty!",
            type: "error",
          });
          this.submitting = false;
        }
      }
    },
    example() {
      // window.location.href = "https://crispr-aidit.com/examples.fasta";
      if (this.exampleShow === true) {
        this.exampleShow = false;
      } else {
        this.exampleShow = true;
      }
    },
    active(i) {
      // console.log(i)
      if (this.fastaSequence) {
        this.hideAfter = true;
      }
      this.num = i;
    },
    copy() {
      this.hideAfter = true;
      this.fastaSequence = JSON.parse(
        JSON.stringify(this.placeholderFastaSequence)
      );
    },
    clear() {
      this.fastaSequence = "";
      this.hideAfter = false;
    },
    copyGene() {
      this.GeneName = "Ddx5";
      this.inputFn(500);
    },
    clearGene() {
      this.GeneName = "";
    },
  },
};
</script>
<style lang="less" scoped>
.success {
  color: #16b21c !important;
}
.warning {
  color: #ff9800 !important;
}
.error {
  color: red !important;
}
/deep/.el-table__header {
  height: 30px !important;
}
/deep/.el-table__body tr,
.el-table__body td {
  padding: 0 !important;
  height: 20px;
}
/deep/ .el-table__header th {
  padding: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
}
/deep/ .el-table__expanded-cell {
  padding-left: 20px;
}
/deep/.el-table__row td {
  font-size: 12px !important;
}
/deep/.el-table .el-table__cell {
  padding: 0 !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  border-top: solid 2px #e6e6e6;
  border-left: solid 2px #e6e6e6;
  border-right: solid 2px #e6e6e6;
  border-bottom: 6px solid #ffffff;
  transform: translateY(3px);
}
.el-menu--horizontal > .el-menu-item {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  border-bottom: 6px solid #ffffff;
  transform: translateY(3px);
}
/deep/ .el-message {
  display: none !important;
}
/deep/.el-input--suffix .el-input__inner {
  color: #1fa1a0;
}

h6 {
  display: inline-block;
  margin-right: 20px;
}
.place {
  position: relative;
}

/deep/.place .el-textarea__inner {
  position: absolute;
  z-index: 1;
  background-color: transparent;
}
/deep/.el-checkbox__label {
  font-size: 15px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1fa1a0;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1fa1a0;
  border-color: #1fa1a0;
}
.place::after {
  position: absolute;
  padding: 5px 15px;
  font-family: Courier New;
  white-space: pre-wrap;
  content: ">NM_001199868\D\A ACATCCGGCCGCCGGCACTGGATTGCTTCTGTCTGGCGGCGGCAGCATGGCGGCGGGGGCGGCTGAGGCAGCTGTAGCGGCCGTGGAGGAGGTCGGCTCAGCCGGGCAGTTTGAGGAGCTGCTGCGCCTCAAAGCCAAGTCCCTCCTTGTGGTCCATTTCTGGGCACCATGGGCTCCACAGTGTGCACAGATGAACGAAGTTATGGCAGAGTTAGCTAAAGAACTCCCTCAAGTTTCATTTGTGAAGTTGGAAGCTGAAGGTGTTCCTGAAGTATCTGAAAAATATGAAATTAGCTCTGTTCCCACTTTTCTGTTTTTCAAGAATTCTCA";
  word-wrap: break-word;
  word-break: break-all;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: #cecece;
  opacity: 1;
  top: 0;
  left: 0;
  height: calc(100% + 10px);
  width: 100%;
  overflow: hidden;
}
.el-textarea.place.hideAfter::after {
  display: none;
}

p {
  margin-bottom: 0px;
}
hr {
  width: 150px;
  height: 8px;
  background-color: #1fa1a0;
  border: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.geneName,
.Genome {
  display: inline-block;
}
.geneName h3,
.Genome h3 {
  height: 22px;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  opacity: 1;
  margin-bottom: 10px;
}

.Genome {
  width: 250px;
  margin-top: 20px;
}
.geneName-input {
  margin-right: 15px;
}
.Genome-select {
  width: 100%;
}
/deep/i.el-select__caret {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 0px;
  /*自定义图片*/
  background: url("../assets/triangle.png") no-repeat scroll center center
    transparent;
  /*自定义图片的大小*/
  background-size: 8px 11px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-arrow-up:before {
  content: "";
}
/deep/.el-select .el-input .el-select__caret.is-reverse {
  transform: rotateZ(90deg);
}
/deep/.el-select .el-input .el-select__caret {
  transform: rotateZ(0deg);
}
h1 {
  margin-top: 30px;
}
.prediction {
  height: auto;
  padding-bottom: 80px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;
  .contentArea {
    border: solid 1px #e6e6e6;
    padding: 20px;
    width: 100%;
    height: auto;
    .example {
      height: 17px;
      font-size: 15px;
      font-weight: 400;
      color: #1fa1a0;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
      margin-left: 30px;
    }
    .download {
      display: inline-block;
      a {
        margin-left: 20px;
        font-size: 15px;
        color: #1fa1a0;
      }
    }
    .example-input {
      width: 80%;
      background: #ffffff;
      border: 1px solid #cecece;
      opacity: 1;
      margin-left: 30px;
      div {
        padding: 7px;
        border-radius: 0px;
        font-size: 14px;
        font-family: Courier New;
        word-break: break-all;
        opacity: 1;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        width: 100%;
        margin-bottom: 20px;
        .title {
          cursor: pointer;
          height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          opacity: 1;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        .FASTA-file {
          .fileValue {
            font-size: 15px;
            font-weight: 400;
            color: #606266;
            opacity: 1;
          }
          p {
            height: 22px;
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            opacity: 1;
            margin: 10px 0;
          }
          .file {
            display: inline-flex;
            cursor: pointer;
            margin: 10px 0;
            align-items: center;
            .icon {
              margin-right: 15px;
              cursor: pointer;
            }
            .crispr_test {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        .gene-name {
          div {
            .el-input {
              width: 250px;
              height: 38px;
              margin-right: 20px;
              /deep/.el-input__inner {
                width: 100%;
                height: 100%;
                border: 1px solid #cecece;
                opacity: 1;
                border-radius: 5px;
                color: #1fa1a0;
              }
            }
            .el-select {
              height: 38px;
              /deep/.el-input__inner {
                color: #1fa1a0;
                height: 38px;
                border: 1px solid #cecece;
                opacity: 1;
                border-radius: 5px;
              }
            }
          }
          p {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            opacity: 1;
            margin: 10px 0;
          }
        }
        .FASTA-text {
          .el-textarea {
            height: 100px;
            margin-bottom: 20px;
            /deep/.el-textarea__inner {
              height: 120px;
              border: 1px solid #cecece;
              opacity: 1;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 400;
              color: #1fa1a0;
              opacity: 1;
            }
          }
          p {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            opacity: 1;
            margin: 10px 0;
          }
        }
      }
    }
  }
  .editing-enzyme {
    width: 100%;
    margin-top: 30px;
    .el-checkbox {
      margin-top: 20px;
    }
    .select-type {
      display: flex;
      width: 100%;
      h3 {
        height: 22px;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        opacity: 1;
      }
      .Enzyme {
        margin-right: 15px;
        width: 250px;
        margin-top: 20px;
        h3 {
          margin-bottom: 10px;
        }
        .el-select {
          width: 100%;
          /deep/.el-input__inner {
            width: 100%;
            background: #ffffff;
            opacity: 1;
            border-radius: 5px;
            font-weight: 400;
            color: #1fa1a0;
          }
        }
      }
    }
    p {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .autoSendEmail {
    width: 100%;
    margin-top: 30px;
    p {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
    .el-checkbox {
      width: 100%;
      margin-top: 20px;
    }
  }
  .models {
    width: 100%;
    margin-top: 30px;
    button {
      border: 0px;
      background-color: transparent;
    }
    img {
      width: 18px;
    }
    .checkbox {
      width: 100%;
      margin-top: 20px;
    }

    p {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .params {
    width: 100%;
    // height: 125px;
    margin-top: 30px;
    .filter {
      // display: flex;
      width: 100%;
      margin-top: 20px;
      span {
        line-height: 40px;
        margin-right: 15px;
      }
      .el-input {
        width: 80px;
        height: 38px;
        margin-right: 20px;
        /deep/.el-input__inner {
          text-align: center;
          width: 100%;
          //   height: 38px;
          background: #ffffff;
          opacity: 1;
          border-radius: 5px;
          font-weight: 400;
          color: #1fa1a0;
        }
      }
    }
    p {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      opacity: 1;
    }
  }
  .submit {
    cursor: pointer;
    width: 300px !important;
    height: 38px;
    background: #1fa1a0;
    opacity: 1;
    border-radius: 5px;
    width: 53px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    border: 0px;
    margin-top: 100px;
    border: #1fa1a0 1px solid;
    transition: 1s;
  }
  .submit:hover {
    background-color: #ffffff;
    color: #1fa1a0;
  }
  .target-top {
    width: 100%;
    height: auto;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    margin-top: 33px;
    margin-bottom: 33px;
    text-align: justify;
    p {
      text-indent: 2em;
    }
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }
}

// 笔记本电脑
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .prediction {
    width: 88%;
  }
  .contentArea {
    width: 92%;
  }
  hr {
    margin-top: 20px;
  }
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  hr {
    width: 25% !important;
    margin-top: 15px;
  }
  h1 {
    margin-bottom: 10px;
  }
 
  .prediction {
    width: 88%;
    position: relative;
    padding-bottom: 150px;
    .target-top {
      margin-top: 0px;
      margin-bottom: 10px;
      span {
        font-size: 20px;
      }
    }
    .contentArea {
      .example {
        margin-left: 0px;
      }
      .download a {
        margin-left: 0px;
      }
      .example-input {
        width: 100%;
        margin-left: 0px;
      }
      ul {
        li {
          .FASTA-text {
            .el-textarea {
              width: 100%;
            }
          }
        }
      }
    }
    .params {
      margin-top: 20px;
      .filter {
        margin-top: 0px;
        .el-input {
          width: 65px;
        }
        span {
          display: block;
          margin-right: 0px;
          font-size: 15px;
        }
      }
      p {
        font-size: 20px;
      }
    }
    .models {
      margin-top: 20px;
      p {
        font-size: 20px;
      }
      .checkbox {
        margin-top: 10px;
      }
    }
    .editing-enzyme {
      margin-top: 20px;
      line-height: 25px;
      p {
        font-size: 20px;
      }
      .select-type {
        .Enzyme {
          margin-top: 10px;
        }
      }
    }
    .submit {
      margin-top: 50px;
      width: 310px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .el-menu--horizontal > .el-menu-item,
  .el-menu--horizontal > .el-menu-item.is-active {
    font-size: 20px;
  }
  .Genome {
    margin-top: 10px;
  }

  .el-checkbox {
    display: block;
  }
  .el-table_1_column_1 {
    width: 100px;
  }
  .geneName {
    width: 100%;
  }
}
</style>
<style>
.tooltipStyle {
  max-width: 400px;
  line-height: 20px;
  text-align: justify;
}
.textExample {
  color: #1fa1a0;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}
</style>